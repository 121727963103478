import axios from "axios";
import React, { useEffect, useState } from "react";

const DailyTime = () =>{
  const current = new Date();
const firstDatemonth = `0${current.getDate()}-${current.getMonth()+1}`;
const lastDatemonth = `${current.getDate()}-${current.getMonth()+1}`;
// console.log(lastDatemonth);

// console.log('first 10 days date month is',firstDatemonth);
// console.log('last 20 days date month is',lastDatemonth);


// console.log(date);
const onlydate = `${current.getDate()}`;
//console.log('onlydate is',onlydate);
const moonDate = onlydate < 10 ? firstDatemonth : lastDatemonth ;
const[times,setTimes] = useState([]);
const getTime = async () =>{
  try {
  const response = await axios.get('https://talabulilm.in/getData.php?slimit=1&elimit=359')
  setTimes(response.data)
  } catch (error) {
  console.log(error)
  }
  };
  
  useEffect(() => {
  getTime();
  },[])

    return (
<>
      {times.map((items,index)=>{
        return(
        <div  key={index}>
        {moonDate === items?.date && (
        <div class="contentSection-card dailyTime">
            <div className="dailyTime__item">
              <p>Shuru-E-Fajr / <span>Khatm-E-Saher</span></p>
              <span className="dailyTime__item--namaz-time">{items.sfks} AM</span>
            </div>
            <div className="dailyTime__item">
              <p>Khatm-E-Fajr / <span>Tulu-E-Aftab</span></p>
              <span className="dailyTime__item--namaz-time">{items.kfta} AM</span>
            </div>
            <div className="dailyTime__item">
              <p><span>Shuru-E-Zohar</span> / Zawal-E-Aftab</p>
              <span className="dailyTime__item--namaz-time">{items.szza} PM</span>
            </div>
            <div className="dailyTime__item">
              <p>Khatm-E-Zohar / <span>Shuru-E-Asar</span></p>
              <span className="dailyTime__item--namaz-time">{items.kzsa} PM</span>
            </div>
            <div className="dailyTime__item">
              <p>Ghuroob-E-Aftab / <span>Waqt-E-Iftar</span></p>
              <span className="dailyTime__item--namaz-time">{items.gawi} PM</span>
            </div>
            <div className="dailyTime__item">
              <p>Khatm-E-Maghrib / <span>Shuru-E-Isha</span></p>
              <span className="dailyTime__item--namaz-time">{items.kmsi} PM</span>
            </div>
        </div>
        )}
        </div>
         )
        })}
</>
    )
  
}

export default DailyTime;
